
export default {
  props:{
    region:{
      type:String,
      default:'uk',
    },
  },
  data(){
    return{
      centre: this.region === 'uk' ? 'Centre' : 'Center'
    }
  },
}
